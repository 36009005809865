export const theme = {
  colors: {
    button: '#f5c271',
    buttonHover: '#fab948',
    buttonFormHover: '#e3ad58',
    textColor: '#2e2f42',
    borderColor: '1px solid #e3e3e3',
    primary: '#daa520',
    secondary: '#b68a1c',
    accent: '#ff8900',
    placeHolder: 'rgba(117, 117, 117, 0.9)',
    label: '#8e8f99',
    text: '#1a1919',
    whiteColor: '#ffffff',
    blackColor: '000000',
    sectionBcgColor: '#f4f4fd',
    strokeEyeColor: '#efede8',
    colorGradientTo: '#a27c53',
    colorGradientFrom: '#faf5d1',
    errorColor: '#e60505',
    heroTextColor: '#F8F3EF',
    footerBcgColor:
      'linear-gradient( 7deg, rgba(65, 63, 58, 1) 0%, rgba(0, 0, 0, 1) 58% )',
    footerTextColor: '#94a1ab',
    formBcgRadGradient:
      'radial-gradient(circle at center, #000000 20%, rgba(65, 63, 58, 1) 80%)',
    restoreLink: '#1022eb',
    restoreHoverLink: '#08149a',
    lessonLinkHover: '#0f16d6',
    lGradientColor:
      'linear-gradient( rgba(46, 47, 66, 0.7), rgba(46, 47, 66, 0.7) )',
  },

  space: [0, 2, 4, 5, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'system-ui, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: {
    xs: '12px',
    s: '14px',
    m: '18px',
    l: '22px',
    xl: '36px',
    xxl: '64px',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  borders: {
    none: 'none',
    normal: '1px solid rgba(25, 26, 21, 0.2)',
    card: '2px solid rgba(25, 26, 21, 0.2)',
    hover: '1px solid rgba(25, 26, 21, 0.6)',
    white: '1px solid #ffffff',
  },
  radius: {
    none: '0',
    normal: '4px',
    round: '30px',
  },
  shadows: {
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
    boxShadow:
      '0px 2px 1px rgba(45, 46, 62, 0.08), 0px 1px 1px rgba(46, 47, 66, 0.16), 0px 1px 6px rgba(46, 47, 66, 0.08)',
  },
  transition: {
    general: '250ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
};
