import Logo from '../Logo/Logo';
import { ROUTES, SOCIAL_LINKS } from '../../constants/constants';
import sprite from '../../assets/sprite.svg';
import { Container } from '../../assets/generalStyles/generalStyles';
import {
  CopyrightText,
  RightSection,
  FooterTitle,
  PrivacyLink,
  SocialLinks,
  FooterEl,
  FooterWrapper,
  SocialIcon,
  SocialLink,
} from './Footer.styled';

const Footer = () => {
  const getCurrentYear = () => new Date().getFullYear();

  return (
    <FooterEl>
      <Container>
        <FooterWrapper>
          <Logo>Logo</Logo>
          <CopyrightText>
            Copyright © {getCurrentYear()} “BelieveInYourself” <br /> Всі
            матеріали даного сайту є об’єктами авторського права. Забороняється
            копіювання, розповсюдження чи будь-яке інше використання інформації
            і об’єктів без письмової згоди правовласника.
          </CopyrightText>
          <RightSection>
            <FooterTitle>Документи та правила</FooterTitle>
            <PrivacyLink href={ROUTES.PRIVACY_POLICY}>
              Політіка конфіденційності
            </PrivacyLink>
            <SocialLinks>
              <li>
                <SocialLink
                  target="_blank"
                  rel="noreferrer noopener"
                  href={SOCIAL_LINKS.YOUTUBE}
                >
                  <SocialIcon width="24" height="24">
                    <use href={sprite + '#icon-youtube'}></use>
                  </SocialIcon>
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  target="_blank"
                  rel="noreferrer noopener"
                  href={SOCIAL_LINKS.INSTAGRAM}
                >
                  <SocialIcon width="24" height="24">
                    <use href={sprite + '#icon-instagram'}></use>
                  </SocialIcon>
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  target="_blank"
                  rel="noreferrer noopener"
                  href={SOCIAL_LINKS.TELEGRAM}
                >
                  <SocialIcon width="24" height="24">
                    <use href={sprite + '#icon-send'}></use>
                  </SocialIcon>
                </SocialLink>
              </li>
            </SocialLinks>
          </RightSection>
        </FooterWrapper>
      </Container>
    </FooterEl>
  );
};

export default Footer;
