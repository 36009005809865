import { NavLink } from 'react-router-dom';

import { LogoWrap, SpanBelieve, SpanInYourself, LogoSvg } from './Logo.styled';
import sprite from '../../assets/sprite.svg';
import { ROUTES } from '../../constants/constants';

const Logo = () => {
  return (
    <NavLink to={ROUTES.HOME}>
      <LogoWrap>
        <SpanBelieve>
          Belıeve
          <span>
            <LogoSvg width="8" height="7">
              <use href={sprite + '#icon-heart'}></use>
            </LogoSvg>
          </span>
        </SpanBelieve>
        <SpanInYourself>InYourself</SpanInYourself>
      </LogoWrap>
    </NavLink>
  );
};

export default Logo;
