import styled from 'styled-components';

export const LogoWrap = styled.div`
  display: flex;
  align-items: baseline;
`;

export const SpanBelieve = styled.span`
  position: relative;
  color: ${(props) => props.theme.colors.primary};
  font-family: 'Lobster', sans-serif;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.02em;
  text-shadow: ${(props) => props.theme.shadows.textShadow};

  @media screen and (min-width: 768px) {
    line-height: 1.33;
  }
  @media screen and (min-width: 1440px) {
    font-size: 23px;
  }
`;

export const SpanInYourself = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  text-shadow: ${(props) => props.theme.shadows.textShadow};
  font-size: 20px;

  @media screen and (min-width: 1440px) {
    font-size: 22px;
  }
`;

export const LogoSvg = styled.svg`
  position: absolute;
  top: 0;
  left: 29px;
  fill: #daa520;

  @media screen and (min-width: 768px) {
    top: 2px;
  }
  @media screen and (min-width: 1440px) {
    top: 0;
    width: 11px;
    height: 10px;
  }
`;
