import { URLS } from '../../constants/constants';

export const signup = async ({ username, email, password }) => {
  try {
    console.log('Signup data:', { username, email, password });
    const response = await fetch(`${URLS.BASE_URL}${URLS.SIGN_UP}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        email,
        password,
      }),
    });

    if (!response.ok) {
      throw new Error('Something went wrong! Please try again.');
    }

    const data = await response.json();
    console.log('data SIGN UP', data);
    return data;
  } catch (error) {
    console.error('Error authorization', error);
  }
};

export const signin = async ({ email, password }) => {
  try {
    const response = await fetch(`${URLS.BASE_URL}${URLS.SIGN_IN}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    console.log('response', response);

    if (!response.ok) {
      throw new Error('Authentication failed. Please check your credentials.');
    }

    const data = await response.json();
    console.log('data SIGN IN', data);

    return data;
  } catch (error) {
    console.error('Error during authentication', error);

    throw new Error('Failed to authenticate. Please try again later.');
  }
};

// export const signup = async ({ username, email, password }) => {
//   try {
//     const response = await fetch('https://jsonplaceholder.typicode.com/users', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         username,
//         email,
//         password, // JSONPlaceholder насправді не обробляє паролі, але ми залишимо це для симуляції
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Something went wrong! Please try again.');
//     }

//     const data = await response.json();
//     console.log('User registered:', data); // Це тільки для перевірки, насправді нічого не зберігається
//     return data;
//   } catch (error) {
//     console.error('Error authorization', error);
//   }
// };

// export const signin = async ({ email, password }) => {
//   return {
//     user: {
//       email: email,
//       password: password,
//     },
//     accessToken: 'fake-jwt-token',
//   };
// };

export const signout = async () => {
  try {
    const response = await fetch(`${URLS.BASE_URL}${URLS.SIGN_OUT}`, {
      method: 'POST',
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Something went wrong! Please try again.');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error signing out', error);
  }
};

export const requestPasswordReset = async (email) => {
  try {
    const response = await fetch(`${URLS.RESET_PASSWORD}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Помилка під час запиту на відновлення паролю');
    }

    const data = await response.json();
    return (
      data.message ||
      'Інструкції з відновлення паролю були надіслані на вашу електронну пошту.'
    );
  } catch (error) {
    console.error('Помилка під час запиту на відновлення паролю', error);
    throw error;
  }
};

export const resetPassword = async (newPassword, token) => {
  try {
    const response = await fetch(`${URLS.RESTORE_PASSWORD}/${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newPassword }),
    });

    if (!response.ok) {
      throw new Error('Помилка під час зміни паролю');
    }

    const data = await response.json();
    return data.message || 'Пароль успішно оновлено.';
  } catch (error) {
    console.error('Помилка під час зміни паролю', error);
    throw error;
  }
};
