import MediaQuery from 'react-responsive';

import { ROUTES } from '../../constants/constants';
import sprite from '../../assets/sprite.svg';
import Logo from '../Logo/Logo';
import { Container } from '../../assets/generalStyles/generalStyles';
import {
  HeaderEl,
  HeaderWrapper,
  Navigation,
  Link,
  LinksWrapper,
  HamburgerButton,
} from './Header.styled';
import { useRenderMobileMenu } from './useRenderMobileMenu';

const Header = () => {
  const { isMenuOpen, toggleMenu, renderNavLinks, renderCoursesLink } =
    useRenderMobileMenu();

  return (
    <HeaderEl>
      <Container>
        <HeaderWrapper>
          <Navigation>
            <Logo />
            <MediaQuery minWidth={768}>
              <Link to={ROUTES.HOME}>Домашня сторінка</Link>
              {renderCoursesLink()}
            </MediaQuery>
          </Navigation>
          <MediaQuery minWidth={768}>
            <LinksWrapper>{renderNavLinks()}</LinksWrapper>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <HamburgerButton onClick={toggleMenu}>
              <svg width="32" height="22">
                <use
                  href={
                    sprite +
                    (isMenuOpen ? '#icon-close-modal-btn' : '#hamburger')
                  }
                ></use>
              </svg>
            </HamburgerButton>
          </MediaQuery>
        </HeaderWrapper>
        {isMenuOpen && (
          <MediaQuery maxWidth={767}>
            <LinksWrapper $isOpen={isMenuOpen}>
              <Link to={ROUTES.HOME} onClick={toggleMenu}>
                Домашня сторінка
              </Link>
              {renderCoursesLink(true)}
              {renderNavLinks(true)}
            </LinksWrapper>
          </MediaQuery>
        )}
      </Container>
    </HeaderEl>
  );
};

export default Header;
