import { Routes, Route } from 'react-router-dom';
import { lazy } from 'react';

import { ROUTES } from '../constants/constants';
import { RestrictedRoute } from '../components/RestrictedRoute';
import { PrivateRoute } from '../components/PrivateRoute';

const SharedLayout = lazy(() =>
  import('../components/SharedLayout/SharedLayout')
);
const Home = lazy(() => import('../pages/Home/Home'));
const Signin = lazy(() => import('../pages/Signin/Signin'));
const Signup = lazy(() => import('../pages/Signup/Signup'));
const UserProfile = lazy(() => import('../pages/Profile/UserProfile'));
const RestorePassword = lazy(() =>
  import('../pages/RestorePassword/RestorePassword')
);
const CurrentCourse = lazy(() =>
  import('../pages/CurrentCourse/CurrentCourse')
);
const Lesson = lazy(() => import('../pages/Lesson/Lesson'));
const PaymentPage = lazy(() => import('../pages/PaymentPage/PaymentPage'));
const PrivacyPolicy = lazy(() =>
  import('../pages/PrivacyPolicy/PrivacyPolicyPage')
);
const BecomeRegressionologist = lazy(() =>
  import('../pages/BecomeRegressTariffsPage/BecomeRegressTariffsPage')
);
const CreateYourLifeTariffsPage = lazy(() =>
  import('../pages/CreateYourLifeTariffsPage/CreateYourLifeTariffsPage')
);
const AppointmentPage = lazy(() =>
  import('../pages/AppointmentPage/AppointmentPage')
);

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<SharedLayout />}>
        <Route index element={<Home />} />
        <Route
          path={ROUTES.SIGN_IN}
          element={
            <RestrictedRoute redirectTo={ROUTES.HOME} element={<Signin />} />
          }
        />
        <Route path={ROUTES.SIGN_UP} element={<Signup />} />
        <Route
          path={ROUTES.USER_PROFILE}
          element={
            <PrivateRoute
              redirectTo={ROUTES.SIGN_IN}
              element={<UserProfile />}
            />
          }
        />
        <Route
          path={ROUTES.CURRENT_COURSE}
          element={
            <PrivateRoute
              redirectTo={ROUTES.SIGN_IN}
              element={<CurrentCourse />}
            />
          }
        />
        <Route
          path={ROUTES.LESSON}
          element={
            <PrivateRoute redirectTo={ROUTES.SIGN_IN} element={<Lesson />} />
          }
        />
        <Route path={ROUTES.RESTORE_PASSWORD} element={<RestorePassword />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={ROUTES.PAYMENT} element={<PaymentPage />} />
        <Route
          path={ROUTES.CREATE_YOUR_LIFE_TARIFFS}
          element={<CreateYourLifeTariffsPage />}
        />
        <Route
          path={ROUTES.REGRESSION_TRAINING_TARIFFS}
          element={<BecomeRegressionologist />}
        />
        <Route path={ROUTES.APPOINTMENT} element={<AppointmentPage />} />

        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}
