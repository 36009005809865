import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../services/auth/authProvider';
import { Link } from './Header.styled';
import { ROUTES } from '../../constants/constants';
import { SignoutButton, SigninLink, SignupLink } from './Header.styled';

export const useRenderMobileMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { auth, handleSignout } = useAuthContext();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const onSignoutClick = () => {
    handleSignout();
    navigate(`${ROUTES.HOME}`);
  };

  const renderCoursesLink = (isMobile = false) => {
    return auth.user ? (
      <Link
        to={ROUTES.USER_PROFILE}
        onClick={isMobile ? toggleMenu : undefined}
      >
        Курси
      </Link>
    ) : null;
  };

  const renderNavLinks = (isMobile = false) => (
    <>
      {auth.user && (
        <>
          <SignoutButton
            onClick={() => {
              onSignoutClick();
              if (isMobile) toggleMenu();
            }}
          >
            Вийти
          </SignoutButton>
        </>
      )}
      {!auth.user && (
        <>
          <SigninLink
            to={ROUTES.SIGN_IN}
            onClick={isMobile ? toggleMenu : undefined}
          >
            Вхід
          </SigninLink>
          <SignupLink
            to={ROUTES.SIGN_UP}
            onClick={isMobile ? toggleMenu : undefined}
          >
            Реєстрація
          </SignupLink>
        </>
      )}
    </>
  );

  return {
    isMenuOpen,
    toggleMenu,
    renderNavLinks,
    renderCoursesLink,
  };
};
