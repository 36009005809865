import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const HeaderEl = styled.header`
  padding-top: 24px;
  padding-bottom: 24px;
  color: ${(props) => props.theme.colors.textColor};
  background-color: ${(props) => props.theme.colors.backgroundColor};
  border-bottom: ${(props) => props.theme.borders.normal};
  box-shadow: ${(props) => props.theme.shadows.boxShadow};
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const Link = styled(NavLink)`
  position: relative;
  margin-top: 50px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.02em;

  cursor: pointer;
  transition: color ${(props) => props.theme.transition.general};

  &:hover,
  &:active {
    color: ${(props) => props.theme.colors.secondary};
  }

  &::after {
    position: absolute;
    display: inline-block;
    content: '';
    height: 3px;
    width: 100%;
    right: 0;
    bottom: -35%;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 2px;
  }
  &:hover::after {
    background-color: ${(props) => props.theme.colors.button};
  }
  @media screen and (min-width: 768px) {
    margin-right: auto;
    margin-left: 50px;
    margin-top: 0;
    font-size: 16px;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 0;
    margin-left: 76px;
  }
`;

export const LinksWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: ${({ $isOpen }) => ($isOpen ? '20px' : '0')};
  width: 100%;
  height: ${({ $isOpen }) => ($isOpen ? '100%' : '0')};
  background: #ffffff;
  z-index: 9999;
  overflow: hidden;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
  pointer-events: auto;

  a:nth-of-type(2) {
    margin-top: 0;
  }

  @media screen and (min-width: 768px) {
    position: static;
    margin-top: 0;
    flex-direction: row;
    justify-content: flex-end;
    height: auto;
    opacity: 1;
    padding: 0;
    gap: 10px;
    font-size: 12px;
    max-width: 50%;
    flex-shrink: 0;
  }
  @media screen and (min-width: 1440px) {
    gap: 25px;
  }
`;

export const SigninLink = styled(NavLink)`
  display: inline-block;
  padding: 11px 35px;
  border: ${(props) => props.theme.borders.normal};
  background-color: transparent;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: border ${(props) => props.theme.transition.general};
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.01em;
  border-radius: ${(props) => props.theme.radius.round};

  &:hover,
  &:active {
    border: ${(props) => props.theme.borders.hover};
  }

  @media screen and (min-width: 768px) {
    font-size: 14px;
    padding: 6px 26px;
  }
  @media screen and (min-width: 1440px) {
    padding: 14px 40px;
  }
`;

export const SignupLink = styled(SigninLink)`
  display: inline-block;
  color: #fff;

  border: none;
  transition: background-color ${(props) => props.theme.transition.general};
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.button};
    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: rgba(46, 47, 66, 0.1);
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    border: none;
    &:before {
      width: 100%;
    }
  }

  /* background-color: ${(props) => props.theme.colors.button};

  border: none;
  transition: background-color ${(props) => props.theme.transition.general};

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.accent};
    border: none;
  } */
`;

export const SignoutButton = styled.button`
  display: inline-block;
  padding: 11px 35px;
  border: ${(props) => props.theme.borders.normal};
  background-color: transparent;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: border ${(props) => props.theme.transition.general};
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.01em;
  border-radius: ${(props) => props.theme.radius.round};

  &:hover,
  &:active {
    border: ${(props) => props.theme.borders.hover};
  }

  @media screen and (min-width: 768px) {
    font-size: 14px;
    padding: 6px 26px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 18px;
    padding: 14px 40px;
  }
`;

export const HamburgerButton = styled.button`
  position: relative;
  z-index: 11000;
  padding: 0;
  border: none;
  background-color: transparent;
  line-height: 0;
  cursor: pointer;
`;
