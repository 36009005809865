import { useState } from 'react';

import {
  signin,
  signup,
  signout,
  requestPasswordReset,
  resetPassword,
} from '../services/auth/authService';

export default function useAuth() {
  const [auth, setAuth] = useState({ user: null });

  console.log('auth', auth);

  const handleSignup = async (userData) => {
    // console.log('userData', userData);
    try {
      const response = await signup(userData);
      console.log('response', response);

      if (response && response.username && response.email) {
        setAuth({ user: null });
        console.log('Registration successful, please confirm your email.');
        return true;
      } else {
        console.log('Registration failed.');
        return false;
      }
    } catch (error) {
      console.error('Помилка під час реєстрації:', error);
      return false;
    }
  };

  const handleSignin = async (credentials) => {
    console.log('credentials', credentials);
    try {
      const result = await signin(credentials);
      console.log('result', result);

      if (result && result.token) {
        sessionStorage.setItem('authToken', result.token);

        setAuth({
          user: result.user || {},
          accessToken: result.token,
        });

        return true;
      } else {
        console.error('Failed to authenticate');
        return false;
      }
    } catch (error) {
      console.error('Failed to authenticate', error.message);
      return false;
    }
  };

  const handleSignout = async () => {
    await signout();
    setAuth({ user: null, accessToken: null });
  };

  const handleRequestPasswordReset = async (email) => {
    return await requestPasswordReset(email);
  };

  const handleResetPassword = async (newPassword, token) => {
    return await resetPassword(newPassword, token);
  };

  return {
    auth,
    handleSignup,
    handleSignin,
    handleSignout,
    handleRequestPasswordReset,
    handleResetPassword,
  };
}
