import { useAuthContext } from '../services/auth/authProvider';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../constants/constants';

export const PrivateRoute = ({ element, redirectTo = ROUTES.HOME }) => {
  const { auth } = useAuthContext();
  const shouldRedirect = !auth.user;

  return shouldRedirect ? <Navigate to={redirectTo} /> : element;
};
