import styled from 'styled-components';

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (min-width: 768px) {
    max-width: 736px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 1158px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.11;
  text-align: center;

  color: ${(props) => props.theme.colors.textColor};

  @media screen and (min-width: 768px) {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 32px;
  }

  @media screen and (min-width: 1440px) {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 24px;
  }
`;
