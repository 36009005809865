import styled from 'styled-components';

export const FooterEl = styled.footer`
  background: ${(props) => props.theme.colors.footerBcgColor};
  color: ${(props) => props.theme.colors.footerTextColor};
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1440px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
`;

export const CopyrightText = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  max-width: 485px;
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const FooterTitle = styled.h3`
  font-size: 14px;
  color: ${(props) => props.theme.colors.whiteColor};
`;

export const PrivacyLink = styled.a`
  font-size: 14px;
  color: ${(props) => props.theme.colors.footerTextColor};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const SocialLinks = styled.ul`
  display: flex;
  gap: 10px;
`;

export const SocialLink = styled.a`
  color: #ffffff;
  text-decoration: none;
`;

export const SocialIcon = styled.svg`
  fill: #ffffff;
  width: 24px;
  height: 24px;

  &:hover,
  &:focus {
    fill: ${(props) => props.theme.colors.footerTextColor};
  }
`;
