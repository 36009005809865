import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AppRoutes from './router/AppRoutes';

export default function App() {
  return (
    <>
      <Header />
      <main>
        <AppRoutes />
      </main>
      <Footer />
    </>
  );
}
