export const URLS = {
  BASE_URL: 'http://localhost:3001/api/auth/users/',
  SIGN_IN: 'login',
  SIGN_UP: 'register',
  SIGN_OUT: 'logout',
  USER_PROFILE: '/api/user-profile',
  PAYMENT: '/api/payment',
  REFRESH: '/api/refresh',
  RESET_PASSWORD: '/api/request-password-reset',
  RESTORE_PASSWORD: '/api/reset-password',
};

export const ROUTES = {
  HOME: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  COURSES: '/courses',
  CURRENT_COURSE: '/courses/:courseId',
  LESSON: '/lessons/:lessonId',
  SIGN_OUT: '/signout',
  PRIVACY_POLICY: '/privacy-policy',
  USER_PROFILE: '/courses',
  RESTORE_PASSWORD: '/restore-password',
  PAYMENT: '/payment',
  CREATE_YOUR_LIFE_TARIFFS: '/create-your-life/tariffs',
  REGRESSION_TRAINING_TARIFFS: '/regression-training/tariffs',
  APPOINTMENT: '/appointment',
};

export const REGEXES = {
  USER_REGEX: /^[a-zA-Zа-яА-ЯЇїІіЄєҐґ'][a-zA-Zа-яА-ЯЇїІіЄєҐґ0-9-'_]{2,23}$/,
  PWD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/,
  EMAIL_REGEX: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
};

export const SOCIAL_LINKS = {
  INSTAGRAM: 'https://www.instagram.com/anna.regress?igsh=MTVkY3Q3b256d2Fkdw==',
  YOUTUBE: 'https://www.youtube.com/@AnnaRegress',
  TELEGRAM: 'https://t.me/Annaregress',
};

export const CLIENT_ID =
  '506149608115-k7keaeq10kms9vaki6k4qq6d65tp550u.apps.googleusercontent.com';
